<template>
  <div
    v-if="cards.length"
    class="card-slider-container"
  >
    <ClientOnly>
      <Carousel
        ref="carousel"
        v-bind="carouselProps"
        @slide-start="(data) => changeSlide(data as SlideStartEvent)"
        @slide-end="afterSlide"
      >
        <Slide
          v-for="(card, index) in cards"
          :key="card.id"
          class="slide"
        >
          <Card
            v-if="animatedCard"
            :card="card"
            data-aos="fade"
            :data-aos-duration="600 * index + 600"
            :dragging="dragging"
          />
          <Card
            v-else
            :card="card"
            :dragging="dragging"
          />
        </Slide>
        <template #addons>
          <div
            class="custom-arrow slick-arrow slick-prev"
            :class="{ disabled: isFirstSlide }"
            @click="prev"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.64 14.973"
            >
              <path
                d="M23.64 7.487a.915.915 0 0 0-.222-.542l-6.3-6.7A.789.789 0 0 0 16 .215a.812.812 0 0 0-.031 1.114l5.06 5.371H.788a.788.788 0 0 0 0 1.576h20.241l-5.061 5.369A.825.825 0 0 0 16 14.758a.789.789 0 0 0 1.114-.031l6.3-6.7a.7.7 0 0 0 .222-.542Z"
              ></path>
            </svg>
          </div>
          <div
            class="custom-arrow slick-arrow slick-next"
            :class="{ disabled: isLastSlide }"
            @click="next"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.64 14.973"
            >
              <path
                d="M23.64 7.487a.915.915 0 0 0-.222-.542l-6.3-6.7A.789.789 0 0 0 16 .215a.812.812 0 0 0-.031 1.114l5.06 5.371H.788a.788.788 0 0 0 0 1.576h20.241l-5.061 5.369A.825.825 0 0 0 16 14.758a.789.789 0 0 0 1.114-.031l6.3-6.7a.7.7 0 0 0 .222-.542Z"
              ></path>
            </svg>
          </div>
        </template>
      </Carousel>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { Carousel, Slide } from 'vue3-carousel'
import type { CarouselInstance, CarouselProps, SlideStartEvent } from '~/types'

const { isMobile, isLargeMobile, isTabletPortrait } = useCustomMediaQuery()

const props = withDefaults(
  defineProps<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cards?: Record<string, any>[]
    animated?: boolean
    initialSlide?: number
  }>(),
  {
    cards: () => [],
    animated: true,
    initialSlide: 0,
  },
)

const emit = defineEmits<{
  changeSlide: [slideNew: number]
}>()

const allowAnim = ref(true)
const dragging = ref(false)

const carousel = ref<CarouselInstance>(null)
const carouselSize = useElementSize(carousel)

const carouselWidth = computed(() => {
  return isMobile.value || isLargeMobile.value || isTabletPortrait.value
    ? 290
    : 360
})

const carouselProps = computed(
  () =>
    ({
      snapAlign: 'start',
      wrapAround: false,
      transition: 500,
      touchDrag: true,
      itemsToShow: carouselSize.width.value / carouselWidth.value,
    }) satisfies CarouselProps,
)

const carouselData = computed(() => carousel.value?.data)

const isFirstSlide = computed(() => {
  return carouselData.value && carouselData.value.currentSlide.value === 0
})

const isLastSlide = computed(
  () =>
    carouselData.value &&
    carouselData.value.currentSlide.value === carouselData.value.maxSlide.value,
)

const prev = () => {
  carousel.value?.prev()
}

const next = () => {
  carousel.value?.next()
}

const changeSlide = ({ slidingToIndex }: SlideStartEvent) => {
  dragging.value = true
  emit('changeSlide', slidingToIndex)
  allowAnim.value = false
}

const afterSlide = () => {
  dragging.value = false
}

const animatedCard = computed(() => {
  return (
    allowAnim.value &&
    props.animated &&
    !(isMobile.value || isLargeMobile.value || isTabletPortrait.value)
  )
})
</script>

<style lang="scss">
.card-slider-container {
  height: 100%;

  .carousel__slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .carousel__track {
    display: flex;
    height: 100%;
  }

  .slick-slide {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-right: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .custom-arrow {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 100%;
    padding: 0;
    font-size: 0;
    line-height: 0;
    background: $white;

    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    border: none;
    cursor: pointer;
    transform: translateX(100%);

    &.disabled {
      opacity: 0;
      pointer-events: none;
    }

    svg {
      width: 2.36rem;
    }

    &::before {
      content: '';
    }

    &.slick-prev {
      left: 0;
      transform: translateX(-100%);

      svg {
        transform: rotate(180deg);
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .card-slider-container {
    padding-left: 2.5rem;

    .carousel__track {
      padding-left: 2.5rem;
    }

    .custom-arrow {
      display: none;
    }
  }
}
</style>
